.rentalTermsCard {
  flex: 1;
  margin-bottom: 7px;
  margin-right: 7px;
  height: 240px;
  min-width: 250px;
  .rentalTermsCardContent {
    flex: 1;
    width: 100%;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 150px;
  }
}
